@font-face {
  font-family: 'PNC';
  src: url('../fonts/pnc/PNCBrandReg.ttf');
  font-weight: normal;
  font-display: block;
}
@font-face {
  font-family: 'PNC';
  src: url('../fonts/pnc/PNCBrandBol.ttf');
  font-weight: bold;
  font-display: block;
}
@font-face {
  font-family: 'PNC';
  src: url('../fonts/pnc/PNCBrandMed.ttf');
  font-weight: 600;
  font-display: block;
}
